import React, { useEffect } from 'react';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';

const App = () => {
    useEffect(() => {
        // Initialize Application Insights
        const appInsights = new ApplicationInsights({
            config: {
                connectionString: 'InstrumentationKey=b51dadb1-e830-4385-b3d3-fa50fd6661d8;IngestionEndpoint=https://centralindia-0.in.applicationinsights.azure.com/;LiveEndpoint=https://centralindia.livediagnostics.monitor.azure.com/' // Replace with your Connection String
            }
        });
        appInsights.loadAppInsights();
        appInsights.trackPageView({name: "RedirectHomePage"}); // Track initial page view

        // Track custom event (optional)
        appInsights.trackEvent({name: "RedirectEvent"});

        // Redirect after a short delay to allow for telemetry to be sent
        const timer = setTimeout(() => {
            window.location.href = 'https://pearl.shell'; 
        }, 500); // Delay for telemetry to be sent before redirect

        // Cleanup the timer if the component unmounts
        return () => clearTimeout(timer);
    }, []); // Empty dependency array means this runs once on mount

    return null; // This component does not render anything
};

export default App;
